/* App.css */
.App {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 206vh;
  height: 100vh;
  
  perspective: 1000px;
  background: url('/public/control.webp') no-repeat center center fixed;
  background-size: 100%  auto; /* This will make sure the width is always 100% of the viewport */
  background-position: center top; /* This will align the top of the image with the top of the viewport */
  transform: translate(-0.1%, -0.1%);
  position: relative;
}

.container {
  display: flex;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.room-images {
  position: absolute;
  top: 57.5%;
  left: 51.6%;
  width: 23.08vh;
  height: 44vh;
  background-size: cover;
  border: 4px solid rgb(0, 0, 0);
  cursor: pointer;
  z-index: -1;
  transform: translate(-50%, -50%);
}
.room-name {
  position: absolute;
  top: 9%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Orbitron', sans-serif;
  font-size: 3em;
  color: #0ff;
  text-shadow: 0 0 10px #0ff, 0 0 20px #0ff, 0 0 30px #0ff, 0 0 40px #ff00ff, 0 0 30px #ff00ff, 0 0 80px #ff00ff, 0 0 100px #ff00ff, 0 0 150px #ff00ff;
}
/*analysing... text */
.retro-text {
  position: absolute;
  top: 32%;  
  left: 70.5%;  
  font-family: 'Orbitron', sans-serif;  
  font-size: 1em;
  color: #00ff66; /* Turquoise/Green shade */
  text-shadow: 
    0 0 5px #00ff66, 
    0 0 10px #00ff66, 
    0 0 15px #33ff99,
    0 0 20px #33ff99, 
    0 0 25px #33ff99, 
    0 0 30px #33ff99, 
    0 0 35px #33ff99;
}

.blinking-cursor {
  animation: blink 1.2s infinite;
}

@keyframes blink {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .App {
    /* Adjust background image size and positioning */
    background-size: 100%  auto; /* This will make sure the width is always 100% of the viewport */
    background-position: center top; /* This will align the top of the image with the top of the viewport */
    transform: translate(-0.5%, -0.1%);
    height: 130vh;
    overflow: hidden;
  }
  html, body {
    overflow-x: auto; 
    overflow-y: visible;
  }
  .room-images {
    top: 44%;
  }
  .container {
    /* Adjust top positioning of the container */
    top: 35%;
  }

  /* ... any other adjustments you'd like for mobile ... */
  .room-name {
    top: 7%;
  }

  .retro-text {
    top: 25%;  
    font-size: .8em;
  }
}