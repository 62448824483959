/* Knob.css */
.knob {
  position: absolute;
  top: 43%;
  left: 13.5%;
  width: 24vh;
  height: 24vh;
  border-radius: 100%;
  background: #0e0e1d;
  transform-origin: center;
  background-image: url('/public/knob.webp');
  background-size: contain;
  transform: translate(-50%, -50%); /* This is used to perfectly center the knob relative to its position */
  transition: box-shadow 0.3s ease-in-out;
}


.knob:hover {
  box-shadow: 0 0 10px 3px rgba(173, 216, 230, 0.7); /* Light blue glow */
  
}
.knob:hover.active {
  box-shadow: 0 0 15px 7px rgba(0, 255, 255, 0.7); /* Ensure this overrides the hover effect */
}



/* Media query for mobile devices */
@media (max-width: 768px) {
  .knob {
    top: 68%; /* Adjust as necessary */
    left: 45.5%; /* Center it horizontally */
    width: 21vh; /* Adjust for mobile size */
    height: 21vh; /* Adjust for mobile size */
  }
  .knob.touched {
    box-shadow: 0 0 10px 4px rgba(0, 255, 255, 0.7);
  }
}