/* Door.css */
.door {
  position: relative;
  width: 23vh;
  height: 44.2vh;
  top: 7.4%;
  left: 1.75%;
  border: 9px double #000000;
  background: rgba(151, 234, 248, 0.5);
  transition: transform 3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  transform-origin: left;
}

.door.open {
  transform: rotateY(-120deg);
}

.frame {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-size: cover;
  background: transparent;
}

.glass {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
}
@media (max-width: 768px) {
  .door {
    top: -6%; /* Adjust as necessary */
  }
}